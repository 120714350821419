body {
    padding: 0 !important;
    margin: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none; /* prevent text selection when dragging nodes */
    overflow: hidden;
    color-adjust: exact;
}

h1:not(:first-child), h2:not(:first-child), h3:not(:first-child),
h4:not(:first-child), h5:not(:first-child), h6:not(:first-child) {
    margin-top: 5px !important;
}

.inline-container {
    position: relative;
    box-sizing: border-box;
    border: 1px solid white;
    transition: border .3s;
}

.inline-container:hover {
    border: 1px solid #DFE1E6;
}

.inline-container .licence {
    transition: opacity .3s;
    opacity: 0;
}

.inline-container:hover .licence {
    opacity: 1;
}

.inline-container .inline-toolbar {
    transition: opacity .3s;
    opacity: 0;
}

.inline-container:hover .inline-toolbar {
    opacity: 1;
}

button {
    margin: 2px;
}

/* https://github.com/facebook/react/issues/4251 */
button[disabled] {
    pointer-events: none;
}


html:lang(en)>body /* <- Safari only */ .ProseMirror {
    user-select: initial; /* allow text manipulation, would break space in FireFox */
}

/* no additional linebreaks in topics */
.ak-renderer-document,
.ProseMirror,
.code {
    white-space: pre !important;
}

/* reduce space between lines in editor and renderer */
p {
    margin: 0 !important;
}

/* remove additional spacing in editor popping up at the end */
.topic.editing > div > div:last-child > div {
    min-height: initial;
}

/*prevent code block from scrolling horizontally*/
.ak-renderer-document .code-block {
    display: block !important;
}
